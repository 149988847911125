import React from 'react';
import { graphql, navigate, withPrefix } from 'gatsby';
import { getUserLangKey } from 'ptz-i18n';

const RedirectAbout = props => {
  if (typeof window !== 'undefined') {
    const { langs, defaultLangKey } = props.data.site.siteMetadata;
    const langKey = getUserLangKey(langs, defaultLangKey);
    const url = withPrefix(`/${langKey}/about`);
    navigate(url);
  }

  return <div />;
};

export default RedirectAbout;

export const ABOUT_LANG_QUERY = graphql`
  query ABOUT_LANG_QUERY {
    site {
      siteMetadata {
        defaultLangKey
        langs
      }
    }
  }
`;
